/* You can add global styles to this file, and also import other style files */

@import 'katteberg-theme.scss';

.katteberg-theme {
  @include angular-material-theme($katteberg-theme);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

h1, h2, h3, h4, h5, h6 {
    font-family: "Bungee", sans-serif;
  }

  .error-popup {
    background: #EA2027;
    color: #F2F3E2;
  }

  .footer {
    a {
      color: #eb5f44;

      :hover {
        text-decoration: underline;
      }
    }

    img {
      margin-left: 5px;
      margin-top: -5px;
    }
  }